import { Box, Button, Container, Flex, Wrapper } from '@energiebespaarders/symbols';
import { CaretDown } from '@energiebespaarders/symbols/icons/solid';
import React, { useState } from 'react';
import { getAllBlogCategories_allBlogCategories } from '~/types/generated/cms/getAllBlogCategories';
import { getAllBlogPostsWithPagination_allBlogPosts } from '~/types/generated/cms/getAllBlogPostsWithPagination';
import { getAllTags_allBlogTags } from '~/types/generated/cms/getAllTags';
import { getBlogPostsByTagIdWithPagination_allBlogPosts } from '~/types/generated/cms/getBlogPostsByTagIdWithPagination';
import { getPostsByCategoryIdWithPagination_allBlogPosts } from '~/types/generated/cms/getPostsByCategoryIdWithPagination';
import { spotlightBlogPosts_allBlogPosts } from '~/types/generated/cms/spotlightBlogPosts';
import DatoMetaTags, { MetaTag } from '../DatoCms/DatoMetaTags';
import BlogContentHeading, { BlogContentHeadingProps } from './BlogContentHeading';
import Breadcrumbs, { Breadcrumb } from './BreadCrumbs';
import CategoryNav from './CategoryNav';
import PostPreview from './PostPreview';
import PostSpotlight from './PostSpotlight';
import TagCloud from './TagCloud';

type BlogPosts =
  | readonly getBlogPostsByTagIdWithPagination_allBlogPosts[]
  | readonly getAllBlogPostsWithPagination_allBlogPosts[]
  | readonly getPostsByCategoryIdWithPagination_allBlogPosts[];

export type BlogPost =
  | getBlogPostsByTagIdWithPagination_allBlogPosts
  | getAllBlogPostsWithPagination_allBlogPosts
  | getPostsByCategoryIdWithPagination_allBlogPosts;

interface Props {
  fetchNextPosts: (page: number) => void;
  heading: BlogContentHeadingProps;
  posts: BlogPosts;
  postsCount: number;
  tags: readonly getAllTags_allBlogTags[];
  metaTags?: readonly MetaTag[];
  spotlightPosts?: readonly spotlightBlogPosts_allBlogPosts[];
  categories?: readonly getAllBlogCategories_allBlogCategories[];
  showSpotlightPosts?: boolean;
  breadCrumbs?: Breadcrumb[];
}

export const blogPostsPerPage = 15;

const BlogContent: React.FC<Props> = ({
  fetchNextPosts,
  heading,
  posts,
  postsCount,
  spotlightPosts,
  tags,
  categories,
  showSpotlightPosts,
  breadCrumbs,
  metaTags,
}) => {
  const [page, setPage] = useState(0);

  const isFirstPage = page === 0;
  const isLastPage = (page + 1) * blogPostsPerPage >= postsCount;

  const handleLoadMore = () => {
    fetchNextPosts(page + 1);
    setPage(page + 1);
  };

  const orderedSpotlightPosts = spotlightPosts?.length
    ? spotlightPosts
        .slice()
        .sort((a, b) => Number(a.spotlightPosition) - Number(b.spotlightPosition))
    : [];

  return (
    <>
      {!!metaTags?.length && <DatoMetaTags metaTags={metaTags} />}
      <Wrapper bgColor="floral" py={10}>
        <Container pb={10}>
          <Flex flexDirection="column" px={[2, 2, 2, 0]}>
            {breadCrumbs && <Breadcrumbs crumbs={breadCrumbs} />}

            {showSpotlightPosts && (
              <>
                <BlogContentHeading heading={{ text: 'Uitgelicht', as: 'h2' }} />
                <Flex flexWrap="wrap" flexDirection={['row-reverse', 'row']} mx={-1} mb={10}>
                  {orderedSpotlightPosts.length > 0 && (
                    <Box px={1} width={[1, 1, 2 / 3]} mb={4}>
                      <PostSpotlight post={orderedSpotlightPosts[0]} />
                    </Box>
                  )}
                  <Box px={1} width={[1, 1, 1 / 3]} style={{ display: 'flex', flexGrow: 1 }} mb={4}>
                    <CategoryNav categories={categories || []} tags={tags} />
                  </Box>
                  {orderedSpotlightPosts.slice(1, 3).map(post => (
                    <Box mb={4} key={post.id} px={1} width={[1, 1 / 2]} style={{ display: 'flex' }}>
                      <PostPreview post={post} />
                    </Box>
                  ))}
                </Flex>
              </>
            )}

            <BlogContentHeading
              text={heading.text}
              heading={{ text: heading.heading.text, as: heading.heading.as }}
            />

            <Flex flexWrap="wrap" mx={-1} id="postsContainer">
              {posts.map(post => (
                <Box
                  key={post.id}
                  px={1}
                  width={[1, 1 / 2, 1 / 3]}
                  mb={4}
                  style={{ display: 'flex' }} // Required to create responsive equal height cards
                >
                  <PostPreview post={post} />
                </Box>
              ))}
              <Box width={1} px={1} textAlign="center">
                <Button
                  bgColor="green"
                  minimal
                  fluid
                  disabled={isLastPage}
                  fontSize={6}
                  label={
                    isFirstPage && postsCount === 0
                      ? 'Geen artikelen gevonden'
                      : isLastPage
                      ? 'Geen resterende artikelen'
                      : 'Meer artikelen laden'
                  }
                  iconEnd={isLastPage ? null : CaretDown}
                  onClick={handleLoadMore}
                  mr={0}
                />
              </Box>
            </Flex>
          </Flex>
        </Container>
        <Container size="md">
          <TagCloud tags={tags} />
        </Container>
      </Wrapper>
    </>
  );
};

export default BlogContent;
