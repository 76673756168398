import { theme } from '@energiebespaarders/symbols';
import { Heading } from '@energiebespaarders/symbols/helpers';
import styled from 'styled-components';

export interface BlogContentHeadingProps {
  text?: string;
  heading: {
    text: string;
    as: 'h1' | 'h2' | 'h3';
  };
}

const HeadingContainer = styled.div`
  margin-bottom: 0.5em;

  span,
  h1,
  h2,
  h3 {
    display: inline;
    font-size: ${theme.type.scale[4]};
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: Causten, sans-serif;

    @media (min-width: ${theme.breakpoints[2]}) {
      font-size: ${theme.type.scale[3]};
    }
  }
`;

const BlogContentHeading: React.FC<BlogContentHeadingProps> = ({ text, heading }) => {
  return (
    <HeadingContainer>
      {text && <span>{text}</span>} <Heading heading={heading.as}>{heading.text}</Heading>
    </HeadingContainer>
  );
};

export default BlogContentHeading;
