import { Box, Flex } from '@energiebespaarders/symbols';
import { Small } from '@energiebespaarders/symbols/helpers';
import { themify } from '@energiebespaarders/symbols/styles/mixins';
import { useRouter } from 'next/router';
import React from 'react';
import styled from 'styled-components';
import HybridLink from '~/components/HybridLink';

export type Breadcrumb = {
  name: string;
  path: string;
};

const BackgroundTab = styled(Box)<{ $isVisible: boolean }>`
  display: inline-block;
  background-color: ${x => (x.$isVisible ? x.theme.colors.green : 'transparent')};
  border-radius: 6px 6px 0 0;
  min-width: 150px;
`;

const StyledBreadcrumb = styled(HybridLink)<{ active: boolean; $color: string }>`
  color: ${x => themify(x.$color)};

  ${x =>
    // Active styling:
    x.active &&
    `
      font-weight: bold;
  `}
  &:hover {
    color: ${x => (x.$color === 'white' ? 'white' : themify('green'))};
  }
`;

interface BreadcrumbsProps {
  crumbs?: Breadcrumb[];
  withBgTab?: boolean;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  crumbs = [{ name: '← Terug', path: '/blog' }],
  withBgTab,
}) => {
  const { asPath } = useRouter();

  return (
    <BackgroundTab $isVisible={!!withBgTab} px={2} py={1}>
      <Small>
        <Flex mx={-1}>
          {crumbs.map((crumb, index) => (
            <React.Fragment key={`${crumb.name}-${index}`}>
              <Box>
                <StyledBreadcrumb
                  to={crumb.path}
                  active={asPath === crumb.path}
                  $color={withBgTab ? 'white' : 'grayDark'}
                  prefetch={false}
                >
                  {crumb.name}
                </StyledBreadcrumb>
              </Box>
              {index < crumbs.length - 1 && <Box px={1}>&raquo;</Box>}
            </React.Fragment>
          ))}
        </Flex>
      </Small>
    </BackgroundTab>
  );
};

export default Breadcrumbs;
